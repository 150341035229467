<template>
  <v-container>
    <machine-table v-bind="props" :update-route="true"></machine-table>
  </v-container>
</template>
<script>

import MachineTable from '@/components/MachineTable'
import {machineTable} from '@/tables'

export default {
  components: {
    MachineTable
  },
  data () {
    return {
      props: machineTable
    }
  }
}
</script>